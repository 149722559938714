
import { defineComponent } from "vue-demi";
import { REFERRAL_TABLE_OPTIONS } from "@/store/common/default/table";
import CommonTable from '@/components/common/table/index.vue';

export default defineComponent({
  name: 'ReferralList',
  data() {
    return {
      referral: REFERRAL_TABLE_OPTIONS(),
    }
  },
  components: {
    CommonTable
  }
})
