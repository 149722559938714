
import { defineComponent } from "vue-demi";
import Referral from "@/components/referral/index.vue";

export default defineComponent({
  name: "ReferralView",
  components: {
    Referral,
  },
});
